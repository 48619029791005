<template>
  <v-container fluid>
    <v-autocomplete
      v-model="selectedSync"
      :items="itemsSync"
      label="Available Fields"
      outlined
      multiple
      hide-details
    >
      <template v-slot:selection="data">
        <draggable
          :id="data.index"
          :list="selectedSync"
          v-bind="dragOptionsChips"
          :move="move"
          @change="change"
        >
          <v-chip
            draggable
            v-model="selectedSync[data.index]"
            :key="data.item"
            @mousedown.stop
            @click.stop
            label
            v-bind="data.attrs"
            :input-value="data.selected"
            close
            @click:close="_remove(data.item.value)"
          >
            {{ data.item }}
          </v-chip>
        </draggable>
      </template>
    </v-autocomplete>
  </v-container>
</template>

<script>
import draggable from "vuedraggable";

export default {
  components: {
    draggable
  },
  data() {
    return {
      items: ["123", "1244", "45345"],
      dragged: {
        from: -1,
        to: -1,
        newIndex: -1
      },
      selectedSync: ["a"],
      itemsSync: ["aaaaa", "bbbbbb", "ccccccc", "ddddddd", "eeeeeeee"]
    };
  },
  computed: {
    dragOptionsChips() {
      return {
        animation: 200,
        group: "group",
        disabled: false,
        ghostClass: "ghost",
        sort: true
      };
    }
  },
  methods: {
    toggle: function(todo) {
      todo.done = !todo.done;
    },
    move: function(value) {
      this.dragged = {
        from: parseInt(value.from.id),
        to: parseInt(value.to.id),
        newIndex: value.draggedContext.futureIndex
      };
    },
    change: function(value) {
      if (value.removed) {
        // insert
        this.selectedSync.splice(
          this.dragged.to + this.dragged.newIndex,
          0,
          this.selectedSync[this.dragged.from]
        );
        // delete
        if (this.dragged.from < this.dragged.to)
          // LTR
          this.selectedSync.splice(this.dragged.from, 1);
        // RTL
        else this.selectedSync.splice(this.dragged.from + 1, 1);
      }
    }
  }
};
</script>
